import { useQuery } from "@tanstack/react-query";
import zakat from '../../assets/images/project/zakat.jpg';
import pic14 from '../../assets/images/project/pic14.jpg';
import pic15 from '../../assets/images/project/pic15.png';
import pic16 from '../../assets/images/project/pic16.jpg';
import pic17 from '../../assets/images/project/pic17.png';
import pic18 from '../../assets/images/project/pic18.jpg';
import pic19 from '../../assets/images/project/pic19.jpg';

export const useProjects = () => {
    const cardData = [
        {id: "5cdc71af-a72d-4467-96d4-ca2cb50ae2b7", cardid:'1', image: zakat, progres:"75%", title:"ZAKAT", subtitle:"Investissez dans l'avenir de ceux qui en ont besoin", authar:"Cheyenne Curtis", raised:"", days:""},
        {id: "cfab5719-cd2d-4b4c-9755-dec7853b169d", cardid:'2', image: pic14, images: [{image: pic14}, {image: pic18}, {image: pic19}] ,progres:"85%", title:"ORPHELINS", subtitle:"Faites un don pour soutenir les orphelins en difficulté", authar:"Kaylynn Donin", raised:"35,542", days:"63"},
        {id: "2d7d44c2-c250-4d09-b823-88d8ed1c5e85", cardid:'3', image: pic15, progres:"70%", title:"AIDE ALIMENTAIRE", subtitle:"Faites un don pour nourrir les personnes dans le besoin", authar:"Adam Jordon", raised:" 2,542", days:"23"},
        {id: "ff6c4a67-b051-4556-a82b-7ff56ce981d2", cardid:'4', image: pic16, progres:"40%", title:"REPAS OFFERT", subtitle:"Offrez un repas chaud pour nourrir les plus démunis", authar:"Kaylynn Donin", raised:"6,542", days:"35"},
        {id: "97fbe41b-f180-4d23-b0d2-273fe79fc58f", cardid:'5', image: pic17, progres:"30%", title:"PUITS", subtitle:"Financer des puits en Afrique et transformez des vies", authar:"Richard Hart", raised:"1,542", days:"47"},
        // {cardid:'5', image: pic17, image2: avat5, progres:"30%", title:"ORPHELINS", subtitle:"Offrez un jouet pour illuminer leur journée et faire sourire leur visage", authar:"Richard Hart", raised:"1,542", days:"47"}
    ];
    return useQuery(["projects"], () => cardData)
}