import React from 'react';
import Route from './pages/Route';
import UserContext from './configuration/userContext';
import useAuthListener from "./configuration/useAuthListener";
import './configuration/i18n';

import "./assets/css/style.css";
//import "./assets/css/skin/skin-1.css";
import "swiper/css";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

function App() {
  const { user } = useAuthListener();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 10 * 60 * 1000,
        cacheTime: 10 * 60 * 1000
      },
      mutations: {
        staleTime: 10 * 60 * 1000,
        cacheTime: 10 * 10 * 1000,
        retry: false
      }
    }
  })
  return (
    <QueryClientProvider client={queryClient}>
      <UserContext.Provider value={{ user }}>
        <Route />
        </UserContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
