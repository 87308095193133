import { useQuery } from "@tanstack/react-query";
import zakat from '../../assets/images/project/zakat.jpg';
import pic14 from '../../assets/images/project/pic14.jpg';
import pic15 from '../../assets/images/project/pic15.png';
import pic16 from '../../assets/images/project/pic16.jpg';
import pic17 from '../../assets/images/project/pic17.png';
import pic18 from '../../assets/images/project/pic18.jpg';
import pic19 from '../../assets/images/project/pic19.jpg';
import pic20 from '../../assets/images/project/pic20.png';

export const useProject = (id) => {
    const cardData = [{
        id: "5cdc71af-a72d-4467-96d4-ca2cb50ae2b7",
        cardid: '1',
        image: zakat,
        images: [{
            image: pic14
        }, {
            image: pic18
        }],
        progres: "75%",
        title: "ZAKAT",
        subtitle: "Investissez dans l'avenir de ceux qui en ont besoin",
        authar: "Cheyenne Curtis",
        raised: "",
        days: "",
        comments: [{
                name: "John Doe",
                comment: "Je suis tellement impressionné par l'impact que votre association a sur la vie des orphelins. En tant que parent, je sais à quel point il est important pour les enfants de se sentir aimés et soutenus, et je suis fier de contribuer à cette cause en faisant un don. Merci de faire une différence!"
            },
            {
                name: "John Doe",
                comment: "Je suis récemment tombé sur votre site et je suis très touché par la manière dont vous aidez les orphelins en difficulté. En tant que personne qui a grandi sans parents, je sais à quel point il est difficile de se débrouiller seul. Merci de donner de l'espoir et de l'aide aux enfants qui en ont besoin."
            },
            {
                name: "John Doe",
                comment: "Je cherchais une association à soutenir et votre site m'a convaincu. J'aime l'idée de pouvoir offrir des jouets et des fournitures scolaires pour aider les orphelins à grandir et à apprendre. Vous faites un travail incroyable et je suis heureux de pouvoir aider de quelque manière que ce soit."
            }
        ],
        description: "Nous sommes une association qui travaille pour aider les plus démunis, en particulier ceux qui sont confrontés à la pauvreté et à l'incertitude de l'avenir. Nous croyons fermement que chaque individu mérite un soutien et une chance de se développer, et nous travaillons dur pour faire en sorte que la zakat soit utilisée de manière efficace et équitable. Nous offrons une variété de programmes de soutien pour aider les personnes à sortir de la pauvreté, notamment des programmes d'éducation, de santé, de logement et d'emploi. En investissant votre zakat dans notre association, vous pouvez avoir un impact significatif sur la vie de ceux qui en ont le plus besoin. Rejoignez-nous dans notre mission de changer l'avenir de ceux qui sont dans le besoin, faites un don aujourd'hui."
    },
    {
        id: "cfab5719-cd2d-4b4c-9755-dec7853b169d",
        cardid: '2',
        image: pic14,
        images: [{
            image: pic14
        }, {
            image: pic18
        }],
        comments: [{
                name: "John Doe",
                comment: "Je suis tellement impressionné par l'impact que votre association a sur la vie des orphelins. En tant que parent, je sais à quel point il est important pour les enfants de se sentir aimés et soutenus, et je suis fier de contribuer à cette cause en faisant un don. Merci de faire une différence!"
            },
            {
                name: "John Doe",
                comment: "Je suis récemment tombé sur votre site et je suis très touché par la manière dont vous aidez les orphelins en difficulté. En tant que personne qui a grandi sans parents, je sais à quel point il est difficile de se débrouiller seul. Merci de donner de l'espoir et de l'aide aux enfants qui en ont besoin."
            },
            {
                name: "John Doe",
                comment: "Je cherchais une association à soutenir et votre site m'a convaincu. J'aime l'idée de pouvoir offrir des jouets et des fournitures scolaires pour aider les orphelins à grandir et à apprendre. Vous faites un travail incroyable et je suis heureux de pouvoir aider de quelque manière que ce soit."
            }
        ],
        progres: "85%",
        title: "ORPHELINS",
        subtitle: "Faites un don pour soutenir les orphelins en difficulté",
        authar: "Kaylynn Donin",
        raised: "35,542",
        days: "63",
        description: "Nous croyons fermement que chaque enfant mérite un avenir radieux et nous travaillons sans relâche pour offrir aux orphelins l'opportunité de réaliser leur potentiel. Nous proposons différentes actions pour apporter notre soutien aux orphelins, notamment en offrant des jouets et des fournitures scolaires, en finançant des soins médicaux et en leur fournissant un abri sûr. Nous avons besoin de votre aide pour continuer à faire la différence dans la vie de ces enfants. Faites un don aujourd'hui pour soutenir notre cause et offrir un avenir meilleur aux orphelins en difficulté."
    },
    {
        id: "2d7d44c2-c250-4d09-b823-88d8ed1c5e85",
        cardid: '3',
        image: pic15,
        images: [{
            image: pic15
        }],
        progres: "70%",
        title: "AIDE ALIMENTAIRE",
        subtitle: "Faites un don pour nourrir les personnes dans le besoin",
        authar: "Adam Jordon",
        raised: " 2,542",
        days: "23",
        comments: [{
                name: "John Doe",
                comment: "Je suis tellement impressionné par l'impact que votre association a sur la vie des orphelins. En tant que parent, je sais à quel point il est important pour les enfants de se sentir aimés et soutenus, et je suis fier de contribuer à cette cause en faisant un don. Merci de faire une différence!"
            },
            {
                name: "John Doe",
                comment: "Je suis récemment tombé sur votre site et je suis très touché par la manière dont vous aidez les orphelins en difficulté. En tant que personne qui a grandi sans parents, je sais à quel point il est difficile de se débrouiller seul. Merci de donner de l'espoir et de l'aide aux enfants qui en ont besoin."
            },
            {
                name: "John Doe",
                comment: "Je cherchais une association à soutenir et votre site m'a convaincu. J'aime l'idée de pouvoir offrir des jouets et des fournitures scolaires pour aider les orphelins à grandir et à apprendre. Vous faites un travail incroyable et je suis heureux de pouvoir aider de quelque manière que ce soit."
            }
        ]
    },
    {
        id: "ff6c4a67-b051-4556-a82b-7ff56ce981d2",
        cardid: '4',
        image: pic16,
        images: [{
            image: pic16
        }],
        progres: "40%",
        title: "REPAS OFFERT",
        subtitle: "Offrez un repas chaud pour nourrir les plus démunis",
        authar: "Kaylynn Donin",
        raised: "6,542",
        days: "35",
        comments: [{
                name: "John Doe",
                comment: "Je suis tellement impressionné par l'impact que votre association a sur la vie des orphelins. En tant que parent, je sais à quel point il est important pour les enfants de se sentir aimés et soutenus, et je suis fier de contribuer à cette cause en faisant un don. Merci de faire une différence!"
            },
            {
                name: "John Doe",
                comment: "Je suis récemment tombé sur votre site et je suis très touché par la manière dont vous aidez les orphelins en difficulté. En tant que personne qui a grandi sans parents, je sais à quel point il est difficile de se débrouiller seul. Merci de donner de l'espoir et de l'aide aux enfants qui en ont besoin."
            },
            {
                name: "John Doe",
                comment: "Je cherchais une association à soutenir et votre site m'a convaincu. J'aime l'idée de pouvoir offrir des jouets et des fournitures scolaires pour aider les orphelins à grandir et à apprendre. Vous faites un travail incroyable et je suis heureux de pouvoir aider de quelque manière que ce soit."
            }
        ]
    },
    {
        id: "97fbe41b-f180-4d23-b0d2-273fe79fc58f",
        cardid: '5',
        image: pic17,
        images: [{
            image: pic17
        }, {image: pic20}],
        progres: "30%",
        title: "PUITS",
        subtitle: "Financer des puits en Afrique et transformez des vies",
        authar: "Richard Hart",
        raised: "1,542",
        days: "47",
        comments: [{
                name: "John Doe",
                comment: "Je suis tellement impressionné par l'impact que votre association a sur la vie des orphelins. En tant que parent, je sais à quel point il est important pour les enfants de se sentir aimés et soutenus, et je suis fier de contribuer à cette cause en faisant un don. Merci de faire une différence!"
            },
            {
                name: "John Doe",
                comment: "Je suis récemment tombé sur votre site et je suis très touché par la manière dont vous aidez les orphelins en difficulté. En tant que personne qui a grandi sans parents, je sais à quel point il est difficile de se débrouiller seul. Merci de donner de l'espoir et de l'aide aux enfants qui en ont besoin."
            },
            {
                name: "John Doe",
                comment: "Je cherchais une association à soutenir et votre site m'a convaincu. J'aime l'idée de pouvoir offrir des jouets et des fournitures scolaires pour aider les orphelins à grandir et à apprendre. Vous faites un travail incroyable et je suis heureux de pouvoir aider de quelque manière que ce soit."
            }
        ],
    },
    // {cardid:'5', image: pic17, image2: avat5, progres:"30%", title:"ORPHELINS", subtitle:"Offrez un jouet pour illuminer leur journée et faire sourire leur visage", authar:"Richard Hart", raised:"1,542", days:"47"}
];
    return useQuery(["project", id], () => cardData.find(card => card.id === id))
} 