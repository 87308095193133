import React from 'react';
import {  Route, Routes, Outlet, Navigate } from 'react-router-dom';

import ScrollToTop from './../layouts/ScrollToTop';
import Header from './../layouts/Header';
import Footer from './../layouts/Footer';
import Home from './Home';
import Home2 from './Home2';
import Home3 from './Home3';
import AboutUs from './AboutUs';
import Volunteer from './Volunteer';
import BecomeVolunteer from './BecomeVolunteer';
import Faq from './Faq';
import Certificates from './Certificates';
import AskQuestion from './AskQuestion';
import HappyClients from './HappyClients';
import HowItWorks from './HowItWorks';
import Mission from './Mission';
import TermsCondition from './TermsCondition';
import Error from './Error';
import UnderMaintenance from './UnderMaintenance';
import ComingSoon from './ComingSoon';
import BrowseFundraiser from './BrowseFundraiser';
import BecomeFundraiser from './BecomeFundraiser';
import FundraiserDetail from './FundraiserDetail';
import Project from './Project';
import ProjectCategories from './ProjectCategories';
import ProjectSidebar from './ProjectSidebar';
import ProjectStory from './ProjectStory';
import Blog from './Blog';
import BlogGrid from './BlogGrid';
import BlogList from './BlogList';
import BlogDetails from './BlogDetails';
import ContactUs from './ContactUs';
// import { useQueryClient } from '@tanstack/react-query';
// import zakat from '../assets/images/project/zakat.jpg';
// import pic14 from '../assets/images/project/pic14.jpg';
// import pic15 from '../assets/images/project/pic15.png';
// import pic16 from '../assets/images/project/pic16.jpg';
// import pic17 from '../assets/images/project/pic17.png';


function Index(){
	// const cardData = [
    //     {id: "5cdc71af-a72d-4467-96d4-ca2cb50ae2b7", cardid:'1', image: zakat, progres:"75%", title:"ZAKAT", subtitle:"Investissez dans l'avenir de ceux qui en ont besoin", authar:"Cheyenne Curtis", raised:"", days:""},
    //     {id: "cfab5719-cd2d-4b4c-9755-dec7853b169d", cardid:'2', image: pic14, progres:"85%", title:"ORPHELINS", subtitle:"Faites un don pour soutenir les orphelins en difficulté", authar:"Kaylynn Donin", raised:"35,542", days:"63"},
    //     {id: "2d7d44c2-c250-4d09-b823-88d8ed1c5e85", cardid:'3', image: pic15, progres:"70%", title:"AIDE ALIMENTAIRE", subtitle:"Faites un don pour nourrir les personnes dans le besoin", authar:"Adam Jordon", raised:" 2,542", days:"23"},
    //     {id: "ff6c4a67-b051-4556-a82b-7ff56ce981d2", cardid:'4', image: pic16, progres:"40%", title:"REPAS OFFERT", subtitle:"Offrez un repas chaud pour nourrir les plus démunis", authar:"Kaylynn Donin", raised:"6,542", days:"35"},
    //     {id: "97fbe41b-f180-4d23-b0d2-273fe79fc58f", cardid:'5', image: pic17, progres:"30%", title:"PUITS", subtitle:"Financer des puits en Afrique et transformez des vies", authar:"Richard Hart", raised:"1,542", days:"47"},
    //     // {cardid:'5', image: pic17, image2: avat5, progres:"30%", title:"ORPHELINS", subtitle:"Offrez un jouet pour illuminer leur journée et faire sourire leur visage", authar:"Richard Hart", raised:"1,542", days:"47"}
    // ];
	// const queryClient = useQueryClient();
	// queryClient.setQueryData(["projects"], cardData);
	return(
		<>
			<Routes>
				<Route path='/error-404' exact element={<Error />} />
				<Route path="*" element={<Navigate to="/error-404" />} />
				<Route path='/under-maintenance' exact element={<UnderMaintenance />} /> 
				<Route path='/coming-soon' exact element={<ComingSoon />} />
				<Route path='/index-2' exact element={<Home2 />} />
				<Route path='/index-3' exact element={<Home3 />} />
				<Route  element={<MainLayout />} > 
					<Route path='/' exact element={<Home />} />
					<Route path='/about-us' exact element={<AboutUs />} />
					<Route path='/volunteer' exact element={<Volunteer />} />
					<Route path='/become-a-volunteer' exact element={<BecomeVolunteer />} />
					<Route path='/faq' exact element={<Faq />} />
					<Route path='/certificates' exact element={<Certificates />} />
					<Route path='/ask-a-question' exact element={<AskQuestion />} />
					<Route path='/happy-clients' exact element={<HappyClients />} />
					<Route path='/how-it-works' exact element={<HowItWorks />} />
					<Route path='/mission' exact element={<Mission />} />
					<Route path='/terms-and-condition' exact element={<TermsCondition />} />
					<Route path='/browse-fundraiser' exact element={<BrowseFundraiser />} />
					<Route path='/become-a-fundraiser' exact element={<BecomeFundraiser />} />
					<Route path='/fundraiser-detail/:id' exact element={<FundraiserDetail />} />
					<Route path='/project' exact element={<Project />} />
					<Route path='/project-categories' exact element={<ProjectCategories />} />
					<Route path='/project-sidebar' exact element={<ProjectSidebar />} />
					<Route path='/project-story' exact element={<ProjectStory />} />
					<Route path='/blog' exact element={<Blog />} />
					<Route path='/blog-grid' exact element={<BlogGrid />} />
					<Route path='/blog-list' exact element={<BlogList />} />
					<Route path='/blog-details/:id' exact element={<BlogDetails />} />
					<Route path='/contact-us' exact element={<ContactUs />} />
				</Route>
			</Routes>	
			<ScrollToTop />
		</>
		 		
	)
} 

function MainLayout(){
	
	return (
		<div className="page-wraper">
			<Header />
			<Outlet /> 
			<Footer />
		</div>
	)
  
  };
export default Index;