import React from 'react';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PageBanner = ({maintitle, pagetitle, background, redirect}) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="dz-bnr-inr dz-bnr-inr-sm style-1 text-center overlay-primary-dark" style={{backgroundImage:"url("+ background + ")"}}>
                <div className="container">
                    <div className="dz-bnr-inr-entry">
                        <h1>{t(pagetitle)}</h1>
                        <nav aria-label="breadcrumb" className="breadcrumb-row">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/${redirect ?? ""}`}>{t(maintitle)}</Link></li>
                                <li className="breadcrumb-item active">{t(pagetitle)}</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
};


export default PageBanner;