import React,{useState, forwardRef,useImperativeHandle, useEffect, useRef} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import {Modal} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from "framer-motion";
import { useProjects } from "../../react-query/projects/useProjects";

// const ButtonWrapper = ({ currency, disabled, value }) => {
//     // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
//     // This is the main reason to wrap the PayPalButtons in a new component
//     const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

//     useEffect(() => {
//         dispatch({
//             type: "resetOptions",
//             value: {
//                 ...options,
//                 currency: currency,
//             },
//         });
//     }, [currency]);

 
//      return (<PayPalButtons
//         fundingSource="paypal"
//         style={{"layout":"vertical","label":"donate", color: "gold"}}
//         disabled={disabled}
//         createOrder={(data, actions) => {
//             return actions.order
//                 .create({
//                     purchase_units: [
//                         {
//                             amount: {
//                                 value: "2",
//                                 breakdown: {
//                                     item_total: {
//                                         currency_code: "EUR",
//                                         value: value,
//                                     },
//                                 },
//                             },
//                             items: [
//                                 {
//                                     name: "donation-example",
//                                     quantity: "1",
//                                     unit_amount: {
//                                         currency_code: "EUR",
//                                         value: value,
//                                     },
//                                     category: "DONATION",
//                                 },
//                             ],
//                         },
//                     ],
//                 })
//                 .then((orderId) => {
//                     // Your code here after create the donation
//                     return orderId;
//                 });
//         }}
//     />
//      );
// } 

const DonateButton = ({ currency, amount, disabled }) => {
    const amountRef = useRef(amount);
    useEffect(() => {
      amountRef.current = amount;
    }, [amount]);
  
    return (
      <PayPalButtons
        // forceReRender={[currency, amount]}
        style={{"layout":"vertical","label":"donate", color: "gold"}}
        fundingSource="paypal"
        disabled={disabled}
        
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: amountRef.current,
                  breakdown: {
                    item_total: {
                      currency_code: currency,
                      value: amountRef.current
                    }
                  }
                },
                items: [
                  {
                    name: "Kudshane",
                    description:
                      "All proceeds directly support Kudshane care and recovery.",
                    quantity: "1",
                    unit_amount: {
                      currency_code: currency,
                      value: amountRef.current
                    },
                    category: "DONATION"
                  }
                ]
              }
            ]
          });
        }}
      />
    );
  };


const DonateModal = forwardRef((props, ref) => {

    const [donateModal,setDonateModal] = useState(false);
    useImperativeHandle(ref, () => ({
		handleModalOpen(){
			setDonateModal(true);
		}
	}));
    function handleModalClose(){
		setDonateModal(false);
	}
	
    const nav = useNavigate();
    const FormSubmit = (e) => {
        e.preventDefault();
        nav("/contact-us");
    };

    const [priceSelected, setPriceSelected] = useState(10);

    const { t } = useTranslation();

    const {data: cardData} = useProjects();

    const [state, setState] = useState([]);  
    
    useEffect(() => {
        setState(cardData?.map((card) => {
            return {id: card.id, price: 0}
        }))
    }, [cardData])

    useEffect(() => {
        setPriceSelected(state?.reduce((acc, card) => acc + card?.price, 0) || 0)
    }, [state])

    console.log(state)

    return(    
        <Modal className="fade modal-wrapper" id="modalDonate" show={donateModal} onHide={setDonateModal} centered> 
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Faire un don</h5>
                    <button type="button" className="btn-close" onClick={() => handleModalClose()}><i className="flaticon-close"></i></button>
                </div>
                <div className="modal-body">
                    <div className="d-flex justify-content-end mb-3"><Link to="/contact-us">Envoyer virement <i class="fa-solid fa-paper-plane"></i></Link></div>
                    <form onSubmit={(e)=>FormSubmit(e)}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tag-donate style-1">
                                    {/* <div className="donate-categories">
                                        <div className="form-check" onClick={() => setPriceSelected(10)}>
                                            <input className="form-check-input" type="radio" checked={priceSelected === 10} />
                                            <label className="form-check-label">
                                                10 €
                                            </label>
                                        </div>
                                        <div className="form-check" onClick={() => setPriceSelected(20)}>
                                            <input className="form-check-input" type="radio" checked={priceSelected === 20} />
                                            <label className="form-check-label">
                                            20 €
                                            </label>
                                        </div>
                                        <div className="form-check" onClick={() => setPriceSelected(50)}>
                                            <input className="form-check-input" type="radio" checked={priceSelected === 50} />
                                            <label className="form-check-label">
                                            50 €
                                            </label>
                                        </div>
                                        <div className="form-check" onClick={() => setPriceSelected(100)}>
                                            <input className="form-check-input" type="radio" checked={priceSelected === 100} />
                                            <label className="form-check-label">
                                            100 €
                                            </label>
                                        </div>
                                        <div className="form-check" onClick={() => setPriceSelected(200)}>
                                            <input className="form-check-input" type="radio" checked={priceSelected === 200} />
                                            <label className="form-check-label">
                                            200 €
                                            </label>
                                        </div>
                                    </div> */}

                                    <div className="clearfix">
                <ul 
                    //layout  
                    id="masonry" className="row"
                    //transition={{ duration: 0.3 }}
                >
                    <AnimatePresence>
                        {cardData?.map((item, index)=>{
                            return(                                       
                                <motion.li 
                                    layout
                                    initial={{ scale: 0, opacity: 0 }}
                                    animate={{ scale: 1, opacity: 1 }}
                                    exit={{ scale: 0, opacity: 0 }}
                                    transition={{ duration: 0.2 }}
                                    className="card-container col-xl-4 col-lg-6 col-md-6 col-sm-12 Fashion m-b30" key={index}
                                    //transition={{ duration: 0.5 }}
                                >
                                    
                                    <div className={`dz-card style-2 overlay-skew h-100 d-flex flex-column justify-content-between`}>
                                        <div className="dz-media">
                                            <Link to={`/fundraiser-detail/${item.id}`} state={{ item }}><img style={{maxHeight: "129.09px"}} src={item.image} alt="" /></Link>
                                        </div>
                                        <div className={`dz-info`}>
                                            <ul className="dz-category d-flex justify-content-center">
                                                <li><Link to={`/fundraiser-detail/${item.id}`} state={{ item }}>{item.title}</Link></li>
                                            </ul>
                                            <h6 className="dz-title"><Link to={`/fundraiser-detail/${item.id}`} state={{ item }}>{item.subtitle} </Link></h6>
                                            {/* <Link to={`/fundraiser-detail/${item.id}`} state={{ item }} className="btn btn-donate btn-primary w-100"  data-bs-toggle="modal" data-bs-target="#modalDonate"><i className="flaticon-like me-3"></i> Faire un don </Link> */}
                                            <div className="input-group input-wrapper">
                                        <input type="number" id="price" className="form-control" value={`${String(state?.[index]?.price || 0)}`}  placeholder="Exemple : 50" onChange={(e) => setState((old) => { 
                                            const test = [...old]
                                            test[index] = {id: old[index].id, price: parseInt(e.target.value)}
                                            return test})} />
                                            <span class="suffix">€</span>
                                            
                                    </div>
                                            {/* <div className="progress-bx style-1">
                                                <div className="progress">
                                                    <div className="progress-bar progress-bar-secondary progress-bar-striped progress-bar-animated" style={{width:item.progres}}></div>
                                                </div>
                                               {item.raised && <ul className="progress-tag">
                                                    <li className="raised">
                                                        <i className="las la-coins"></i> Raised: <span className="text-primary">$ {item.raised}</span>
                                                    </li>
                                                    <li className="goal">
                                                        <i className="lar la-calendar"></i> Goal: <span className="text-primary">$70,000</span>
                                                    </li>
                                                </ul>}
                                            </div> */}
                                            {/* <div className="author-wrappper">
                                                <div className="author-media">
                                                    <img src={item.image2} alt="" /> 
                                                </div>
                                                <div className="author-content">
                                                    <div className="author-head">
                                                        <h6 className="author-name">{item.authar}</h6>
                                                        <ul className="rating-list">
                                                            <li><i className="fa fa-star"></i></li>
                                                            {" "}<li><i className="fa fa-star"></i></li>
                                                            {" "}<li><i className="fa fa-star"></i></li>
                                                            {" "}<li><i className="fa fa-star gray-light"></i></li>
                                                            {" "}<li><i className="fa fa-star gray-light"></i></li>
                                                        </ul>
                                                    </div>
                                                    <ul className="author-meta">
                                                        <li className="campaign">12 Campaign</li>
                                                        <li className="location">New York, London</li>
                                                    </ul>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </motion.li>

                            )
                        })}
                        
                    </AnimatePresence>
                    {cardData?.length < 1 ? "Aucun résultat." : ""}
                 </ul>
                 <div style={{gap: "1rem"}} className='d-flex align-items-center'><h6>Total donation : </h6> <h5>{priceSelected}€</h5></div>
            </div> 
                                    {/* <div className="donate-categories">
                                    <div className="form-check">
                                            <input className="form-check-input" type="radio" value={20} checked={priceSelected === 20} onChange={() => setPriceSelected(20)} />
                                            <label className="form-check-label" htmlFo="flexRadioDefault2">
                                            20 €
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                            <label className="form-check-label" htmlFo="flexRadioDefault3">
                                            30 €
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                            <label className="form-check-label" htmlFo="flexRadioDefault4">
                                            50 €
                                            </label>
                                        </div>
                                    </div>
                                    <div className="donate-categories">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
                                            <label className="form-check-label" htmlFo="flexRadioDefault5">
                                            100 €
                                            </label>
                                        </div>
                                    </div>
                                    <div className="donate-categories">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" />
                                            <label className="form-check-label" htmlFo="flexRadioDefault6">
                                            200 €
                                            </label>
                                        </div>
                                    </div> */}
                                    {/* <p>Most Donors donate approx <span>50 €</span> to this Fundraiser</p> */}
                                    {/* <div className="form-group w-100">
                                    <label className="form-label">Autre montant</label>
                                    <div className="input-group">
                                        <input type="number" className="form-control" value={String(priceSelected)}  placeholder="Exemple : 50" onChange={(e) => setPriceSelected(parseInt(e.target.value))} />
                                    </div>
                                </div> */}
                                </div>
                            </div>
                            {/* <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="form-label">Other Amount</label>
                                    <div className="input-group">
                                        <input type="number" className="form-control"  placeholder="Other Amount" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="form-label">Name</label>
                                    <div className="input-group">
                                        <input name="dzName" required="" type="text" className="form-control" placeholder="Jakob Bothman" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="form-label">Email address</label>
                                    <div className="input-group">
                                        <input name="dzEmail" required type="text" className="form-control" placeholder="info@mail.com" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="form-label">Phone Number</label>
                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="Phone Number" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="form-label">Address</label>
                                    <div className="input-group">
                                        <input required type="text" className="form-control" placeholder="Address" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="form-label">Pancard</label>
                                    <div className="input-group">
                                        <input type="number" className="form-control" placeholder="Pancard" />
                                    </div>
                                </div>
                            </div> */}
                      
                                {/* <div className="form-group mb-0 text-center">
                                    <button disabled={priceSelected <= 0} name="submit" type="submit" value="Submit" className="btn paypal btn-block"> <i className="fa-brands fa-paypal m-r10"></i>Proceed To Pay</button>
                                </div> */}
                               
                                 
                        
                        </div>
                        <div className="text-center">
                                <PayPalScriptProvider
                options={{
                    "client-id": "test",
                    components: "buttons",
                    currency: "EUR",

                }}
            ><DonateButton
            disabled={!priceSelected ||priceSelected <= 0}
            currency={"EUR"}
            amount={String(priceSelected)}
        />
                               </PayPalScriptProvider>
                                </div>
                    </form>
                </div>
            </div>                
        </Modal>   
    );
});
export default DonateModal;