import React, {useState, useEffect} from 'react';
import {Dropdown} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import { useProjects } from "../../react-query/projects/useProjects";

//images
import zakat from '../../assets/images/project/zakat.jpg';
import pic14 from '../../assets/images/project/pic14.jpg';
import pic15 from '../../assets/images/project/pic15.png';
import pic16 from '../../assets/images/project/pic16.jpg';
import pic17 from '../../assets/images/project/pic17.png';

import avat1 from '../../assets/images/avatar/avatar1.jpg';
import avat2 from '../../assets/images/avatar/avatar2.jpg';
import avat3 from '../../assets/images/avatar/avatar3.jpg';
import avat4 from '../../assets/images/avatar/avatar4.jpg';
import avat5 from '../../assets/images/avatar/avatar5.jpg';

const ProjectMasonry = ({searchValue}) => {

    const { t } = useTranslation();

    const {data: cardData} = useProjects();
    
    // const cardData = [
    //     {id: "5cdc71af-a72d-4467-96d4-ca2cb50ae2b7", cardid:'1', image: zakat, image2: avat1, progres:"75%", title:"ZAKAT", subtitle:"Investissez dans l'avenir de ceux qui en ont besoin", authar:"Cheyenne Curtis", raised:"", days:""},
    //     {id: "cfab5719-cd2d-4b4c-9755-dec7853b169d", cardid:'2', image: pic14, image2: avat2, progres:"85%", title:"ORPHELINS", subtitle:"Faites un don pour soutenir les orphelins en difficulté", authar:"Kaylynn Donin", raised:"35,542", days:"63"},
    //     {id: "2d7d44c2-c250-4d09-b823-88d8ed1c5e85", cardid:'3', image: pic15, image2: avat3, progres:"70%", title:"AIDE ALIMENTAIRE", subtitle:"Faites un don pour nourrir les personnes dans le besoin", authar:"Adam Jordon", raised:" 2,542", days:"23"},
    //     {id: "ff6c4a67-b051-4556-a82b-7ff56ce981d2", cardid:'4', image: pic16, image2: avat4, progres:"40%", title:"REPAS OFFERT", subtitle:"Offrez un repas chaud pour nourrir les plus démunis", authar:"Kaylynn Donin", raised:"6,542", days:"35"},
    //     {id: "97fbe41b-f180-4d23-b0d2-273fe79fc58f", cardid:'5', image: pic17, image2: avat5, progres:"30%", title:"PUITS", subtitle:"Financer des puits en Afrique et transformez des vies", authar:"Richard Hart", raised:"1,542", days:"47"},
    //     // {cardid:'5', image: pic17, image2: avat5, progres:"30%", title:"ORPHELINS", subtitle:"Offrez un jouet pour illuminer leur journée et faire sourire leur visage", authar:"Richard Hart", raised:"1,542", days:"47"}
    // ];
    const [dropbtn,setDropbtn] = useState('Newest');
    const [popular, setPopular ] = useState([]);
    const [filtered, setFiltered ] = useState([]);
    const [activeGenre, setActiveGenre ] = useState(0);    

    useEffect(()=>{
        fetchPopular();
    },[])
    function  fetchPopular(){
        setPopular(cardData);
        setFiltered(cardData);
    }
    const regex = new RegExp(searchValue, "i");
    useEffect(()=>{
        if(activeGenre===0){
            if(!searchValue)setFiltered(cardData);
            if(searchValue){
                setFiltered(cardData.filter(card => regex.test(card.subtitle)));
            }
            return;
        }
        const filtered = popular.filter((card)=>
         card.cardid.includes(activeGenre)
        );
        setFiltered(filtered.filter(card => `${card.subtitle}`.match(new RegExp(searchValue?.split("").join("."), "i"))));
    }, [activeGenre, searchValue, cardData]);

    return (
        <>
            <div className="row m-b30">
                <div className="col-xl-10 col-lg-9">
                    <div className="site-filters style-1 clearfix">
                        <ul className="filters" data-bs-toggle="buttons">
                            <li className={`btn ${activeGenre === 0 ? "active" : ""}`}>
                                
                                <Link to={"#"}  onClick={()=>setActiveGenre(0)}>Tous</Link> 
                            </li>
                            <li data-filter=".Technology" className={`btn ${activeGenre === 1 ? "active" : ""}`}>
                                
                                <Link to={"#"}  onClick={()=>setActiveGenre(1)}>{t("ZAKAT")}</Link> 
                            </li>
                            <li data-filter=".Medical" className={`btn ${activeGenre === 2 ? "active" : ""}`}>
                                
                                <Link to={"#"} onClick={()=>setActiveGenre(2)}>{t("ORPHANS")}</Link> 
                            </li>
                            <li data-filter=".Business" className={`btn ${activeGenre === 3 ? "active" : ""}`}>
                                
                                <Link to={"#"} onClick={()=>setActiveGenre(3)}>{t("FOOD_AID")}</Link> 
                            </li>
                            <li data-filter=".Fashion" className={`btn ${activeGenre === 4 ? "active" : ""}`}>
                                
                                <Link to={"#"} onClick={()=>setActiveGenre(4)}>{t("SHARE_THE_MEAL")}</Link> 
                            </li>
                            <li data-filter=".Fashion" className={`btn ${activeGenre === 5 ? "active" : ""}`}>
                                
                                <Link to={"#"} onClick={()=>setActiveGenre(5)}>{t("WELLS")}</Link> 
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-xl-2 col-lg-3 text-start text-lg-end m-b20">
                    <Dropdown className="select-drop">
                        <Dropdown.Toggle as="div" className="i-false select-drop-btn">
                            <span>{dropbtn}</span>
                            <i className="fa-regular fa-angle-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={()=>setDropbtn('Newest')}>Newest</Dropdown.Item>
                            <Dropdown.Item onClick={()=>setDropbtn('Oldest')}>Oldest</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className="clearfix">
                <ul 
                    //layout  
                    id="masonry" className="row"
                    //transition={{ duration: 0.3 }}
                >
                    <AnimatePresence>
                        {filtered?.map((item, index)=>{
                            return(                                       
                                <motion.li 
                                    layout
                                    initial={{ scale: 0, opacity: 0 }}
                                    animate={{ scale: 1, opacity: 1 }}
                                    exit={{ scale: 0, opacity: 0 }}
                                    transition={{ duration: 0.2 }}
                                    className="card-container col-xl-4 col-lg-6 col-md-6 col-sm-12 Fashion m-b30" key={index}
                                    //transition={{ duration: 0.5 }}
                                >
                                    
                                    <div className={`dz-card style-2 overlay-skew h-100 d-flex flex-column justify-content-between`}>
                                        <div className="dz-media">
                                            <Link to={`/fundraiser-detail/${item.id}`} state={{ item }}><img style={{maxHeight: "229.09px"}} src={item.image} alt="" /></Link>
                                        </div>
                                        <div className={`dz-info`}>
                                            <ul className="dz-category d-flex justify-content-center">
                                                <li><Link to={`/fundraiser-detail/${item.id}`} state={{ item }}>{item.title}</Link></li>
                                            </ul>
                                            <h5 className="dz-title"><Link to={`/fundraiser-detail/${item.id}`} state={{ item }}>{item.subtitle} </Link></h5>
                                            <Link to={`/fundraiser-detail/${item.id}`} state={{ item }} className="btn btn-donate btn-primary w-100"  data-bs-toggle="modal" data-bs-target="#modalDonate"><i className="flaticon-like me-3"></i> Faire un don </Link>
                                            {/* <div className="progress-bx style-1">
                                                <div className="progress">
                                                    <div className="progress-bar progress-bar-secondary progress-bar-striped progress-bar-animated" style={{width:item.progres}}></div>
                                                </div>
                                               {item.raised && <ul className="progress-tag">
                                                    <li className="raised">
                                                        <i className="las la-coins"></i> Raised: <span className="text-primary">$ {item.raised}</span>
                                                    </li>
                                                    <li className="goal">
                                                        <i className="lar la-calendar"></i> Goal: <span className="text-primary">$70,000</span>
                                                    </li>
                                                </ul>}
                                            </div> */}
                                            {/* <div className="author-wrappper">
                                                <div className="author-media">
                                                    <img src={item.image2} alt="" /> 
                                                </div>
                                                <div className="author-content">
                                                    <div className="author-head">
                                                        <h6 className="author-name">{item.authar}</h6>
                                                        <ul className="rating-list">
                                                            <li><i className="fa fa-star"></i></li>
                                                            {" "}<li><i className="fa fa-star"></i></li>
                                                            {" "}<li><i className="fa fa-star"></i></li>
                                                            {" "}<li><i className="fa fa-star gray-light"></i></li>
                                                            {" "}<li><i className="fa fa-star gray-light"></i></li>
                                                        </ul>
                                                    </div>
                                                    <ul className="author-meta">
                                                        <li className="campaign">12 Campaign</li>
                                                        <li className="location">New York, London</li>
                                                    </ul>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </motion.li>

                            )
                        })}
                        
                    </AnimatePresence>
                    {filtered?.length < 1 ? "Aucun résultat." : ""}
                 </ul>
            </div> 
            <div className="row">
                <div className="col-12 m-sm-t0 m-t30">		
                    <nav className="pagination-bx">
                        <div className="page-item">
                            <Link to={"#"} className="page-link prev"><i className="fas fa-chevron-left"></i></Link>
                        </div>
                        <ul className="pagination">
                            <li className="page-item"><Link to={"#"} className="btn page-link active">1</Link></li>
                            <li className="page-item"><Link to={"#"} className="btn page-link disabled">2</Link></li>
                            <li className="page-item"><Link to={"#"} className="btn page-link disabled">3</Link></li>
                            <li className="page-item"><Link to={"#"} className="btn page-link disabled">4</Link></li>
                        </ul>
                        <div className="page-item">
                            <Link to={"#"} className="btn page-link next disabled"><i className="fas fa-chevron-right"></i></Link>
                        </div>
                    </nav>
                </div>
            </div> 
        </>
    );
};

export default ProjectMasonry;