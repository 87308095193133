import React from 'react';
import {Link} from 'react-router-dom';
import CountUp from 'react-countup';


import slide from './../assets/images/main-slider/pic1.png';

const CounterBlog = [
    {title:"Total Donateurs", number:"456"},
    {title:"Membres", number:"6"},
    {title:"Total Dons", number:"25654"},
];

const OurMission = () =>{
    return(
        <>
            <div className="col-lg-5">
                <div className="dz-media">
                    <img src={slide} alt="image" />
                </div>
            </div>
            <div className="col-lg-7 m-b50">
                <div className="section-head">
                    <h5 className="sub-title">Nos Missions</h5>
                    <h2>Transmettre le sourire et la lumière de l'espoir</h2>
                    <p className="m-t20">Notre but principal est d’Aider les personnes nécessiteux autant que nous le pouvons.
La priorité de cette association sont les enfants, les opprimés et les orphelins. Ce qui est important pour nous c’est de ressentir l’empathie et d’aider les personnes en reflétant une union aux nécessiteux.</p>
                </div>
                <div className="row">
                    {CounterBlog.map((item, index)=>(
                        <div className="col-lg-3 col-sm-4 col-6 m-b30" key={index}>
                            <h2>
                                <span className="counter">
                                    <CountUp
                                        end={item.number} 
                                        separator = ","
                                        duration= "3"
                                    />{item.number === 25654 && "€"}
                                </span>
                            </h2>
                            <span className="counter-text">{item.title}</span>
                        </div>
                    ))} 
                </div>
                <Link to={"/project-story"} className="m-r30 m-sm-r10 btn btn-secondary">Nos Projets <i className="flaticon-right-arrows ps-3"></i></Link>
                <Link to={"/how-it-works"} className="btn btn-outline-dark">Comment ça marche</Link>
            </div>
        </>
    )
}
export default OurMission;