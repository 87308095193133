import axios from "axios";

const axiosClient = axios.create({
    headers: {
        "content-type": "application/json"
    }
});

axiosClient.defaults.baseURL = "https://us-central1-kudshane-805c0.cloudfunctions.net/app"

export default axiosClient;