import React,{useRef} from 'react';
import emailjs from '@emailjs/browser';
import {useSubscribe} from "../../react-query/newsletter/useSubscribe";
import swal from "sweetalert";

import bgimage from './../../assets/images/aboutus/IMG_5719.jpg';
// import {subscribeToNewsletter} from "../../services/subscribe";
// import { useMutation } from "@tanstack/react-query";

const UpdateBlog = () =>{
    // const subscribe = useMutation((email) => subscribeToNewsletter(email));
    const form = useRef();
    const subscribe = useSubscribe();
	const sendEmail = (e) => {
		e.preventDefault();
		// emailjs.sendForm('gmail', 'YOUR_TEMPLATE_ID', e.target, 'd9b2e0f5fc72cb94792110e8ff2028f3-us16')
		//   .then((result) => {
		// 	  console.log(result.text);
		//   }, (error) => {
		// 	  console.log(error.text);
		//   });
        // console.log(e.target)
          subscribe.mutate(e.target.elements.dzEmail.value, {
            onSuccess: (user) => {
                swal('Newsletter', `Vous venez de vous abonner avec l'\adresse : ${user.email}. `, "success");
            }
          });
          e.target.reset()
          
          //alert('form suucessfult submmit');
	 };	
    return(
        <>
            
            <div className="inner-action overlay-primary-dark" style={{backgroundImage:"url("+ bgimage + ")"  ,backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
                <div className="row inner-content justify-content-center">
                    <div className="col-xl-6 col-lg-9">
                        <div className="section-head text-center">
                            <h2 className="title text-white">Ne manquez pas nos mises à jour hebdomadaires sur les dons</h2>
                        </div>
                        <form className="dzSubscribe" ref={form} onSubmit={sendEmail}>
                            <div className="dzSubscribeMsg text-white"></div>
                            <div className="input-group">
                                <input name="dzEmail" required="required" type="email" className="form-control" placeholder="Entrez votre adresse mail" />
                                <div className="input-group-addon">
                                    <button type="submit" className="btn btn-secondary btn-rounded-lg">
                                        <span>S'ABONNER</span>
                                        <i className="fa-solid fa-paper-plane"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
                
        </>
    )
}
export default UpdateBlog;