import React from 'react';
import {Link} from 'react-router-dom';
import CountUp from 'react-countup';


import slide from './../assets/images/main-slider/pic1.png';

const CounterBlog = [
    {title:"Total Donateurs", number:"456"},
    {title:"Membres", number:"6"},
    {title:"Total Dons", number:"25654"},
];

const OurMissionForm = () =>{
    const FormSubmit = (e) => {
        e.preventDefault();
    }
    return(
        <>
            <div className="col-lg-6">
                <div className="dz-media">
                    <img src={slide} alt="image" />
                </div>
            </div>
            <div className="col-lg-6 m-b50">
            {/* <div className="container"> */}
                        <div className="row justify-content-center">
                            <div className="col-xl-12 col-md-9 col-sm-12">
                                <div className="form-wrapper style-3">
                                    <div className="contact-area">
                                        <form className="dz-form dzForm contact-bx" onClick={(e)=>FormSubmit(e)}>
                                            <div className="dzFormMsg"></div>
                                            <input type="hidden" className="form-control" name="dzToDo" value="Contact" />
                                            <input type="hidden" className="form-control" name="reCaptchaEnable" value="0" />
                                            
                                            <div className="row sp15">
                                                <div className="col-md-12">
                                                    <label className="form-label">Full Name</label>
                                                    <div className="input-group">
                                                        <input name="dzName" required type="text" className="form-control" placeholder="Marchelo Queque" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label">Email address</label>
                                                    <div className="input-group">
                                                        <input name="dzEmail" required type="text" className="form-control" placeholder="marseloque@mail.com" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label">Phone Number</label>
                                                    <div className="input-group">
                                                        <input name="dzPhoneNumber" required type="text" className="form-control" placeholder="987 654 3210" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <label className="form-label">Company Name</label>
                                                    <div className="input-group">
                                                        <input name="dzOther[company_Name]" required type="text" className="form-control" placeholder="Marchelo Studios" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <label className="form-label">Message</label>
                                                    <div className="input-group">
                                                        <textarea name="dzMessage" rows="5" required className="form-control" placeholder="Dear Sir/Madam"></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 m-b20">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                        <label className="form-check-label" for="flexCheckDefault">
                                                            Accept terms & conditions
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <button name="submit" type="submit" value="Submit" className="btn btn-secondary effect">SEND</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>	
                                </div>
                            </div>
                        </div>
                    {/* </div> */}
                {/* <div className="row">
                    {CounterBlog.map((item, index)=>(
                        <div className="col-lg-3 col-sm-4 col-6 m-b30" key={index}>
                            <h2>
                                <span className="counter">
                                    <CountUp
                                        end={item.number} 
                                        separator = ","
                                        duration= "3"
                                    />{item.number === 25654 && "€"}
                                </span>
                            </h2>
                            <span className="counter-text">{item.title}</span>
                        </div>
                    ))} 
                </div> */}
                {/* <Link to={"/project-story"} className="m-r30 m-sm-r10 btn btn-secondary">Nos Projets <i className="flaticon-right-arrows ps-3"></i></Link>
                <Link to={"/how-it-works"} className="btn btn-outline-dark">Comment ça marche</Link> */}
            </div>
        </>
    )
}
export default OurMissionForm;