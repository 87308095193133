import React,{useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import {Modal} from 'react-bootstrap';

import PageBanner from '../layouts/PageBanner';
import {CommentBlog} from '../components/BlogDetailsLeftBar';
import { useParams } from 'react-router-dom';

import bg from '../assets/images/banner/bnr4.jpg';
import avat1 from '../assets/images/avatar/avatar1.jpg';
import avat2 from '../assets/images/avatar/avatar2.jpg';
import avat3 from '../assets/images/avatar/avatar3.jpg';
import avat4 from '../assets/images/avatar/avatar4.jpg';
import avat5 from '../assets/images/avatar/avatar5.jpg';
import blog1 from '../assets/images/blog/recent-blog/pic1.jpg';
import blog2 from '../assets/images/blog/recent-blog/pic2.jpg';
import UpdateBlog from '../components/Home/UpdateBlog';
import GallerySlider from '../components/Fundraiser/GallerySlider';
import { useProject } from "../react-query/projects/useProject";
import DonateModal from './../components/Modal/DonateModal';

const postBlog = [
    {image:blog1, title:"How To Teach Education Like A Pro."},
    {image:blog2, title:"Quick and Easy Fix For Your Education."},
];
const teamBlog =[
    {title:"Jake Johnson", image:avat1},
    {title:"Celesto Anderson", image:avat2},
    {title:"John Doe", image:avat3},
    {title:"Jake Johnson", image:avat4}
];

const donorsBlog = [
    {title:"Celesto Anderson", image:avat5, price:"1,812 €"},
    {title:"John Doe", image:avat4, price:"1,564 €"},
    {title:"Celesto Anderson", image:avat3, price:"1,225 €"},
    {title:"Jake Johnson", image:avat2, price:"9,00 €"},
];



const FundraiserDetail = () => {
    const modalRef = useRef();

    const [modalDonate, setModalDonate] = useState(false);
    const [referModal, setReferModal] = useState(false);
    let { id } = useParams();
    const {data: cardData} = useProject(id);
    // console.log([{image: cardData.image}])
    return (
        <>
            <div className="page-content bg-white">
                <PageBanner maintitle="OUR_PROJECTS" pagetitle="PROJECT_DETAIL" background={bg} redirect="project"/>
                <section className="content-inner-2">
                    <div className="container">	
                        <div className="row">
                            <div className="col-xl-8 col-lg-8 m-b30">
						        <div className="fundraiser-single">
                                    <div className="swiper fundraiser-gallery-wrapper">
                                        <GallerySlider imgs={cardData?.images} />
                                    </div>
                                    <h2 className="title">{cardData?.subtitle}</h2>
                                    <p>{cardData?.description}</p>
                                    {/*                                     
                                    <h5>About the Fundraiser</h5>
                                    <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."</p> */}
                                    
                                    <ul className="fundraiser-bottom">
                                        <li>
                                            <Link to={"#"} className="btn btn-primary btn-sm" onClick={() => {modalRef.current.handleModalOpen(); }} data-bs-target="#modalDonate">
                                                <i className="flaticon-like me-2"></i> Faire un don
                                            </Link>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/login/" target="_blank" rel="noreferrer" className="btn facebook btn-primary btn-sm">
                                                <i className="fa-brands fa-facebook-square me-2"></i> Partager
                                            </a>
                                        </li>
                                        <li><a href="https://web.whatsapp.com/" target="_blank" rel="noreferrer" className="btn whatsapp btn-primary btn-sm"><i className="fa-brands fa-whatsapp me-2"></i>Partager</a></li>
                                    </ul>
                                </div>
                                {/* <div className="widget style-1 widget_refer">
                                    <div className="widget-title">
                                        <h4 className="title">Refer a Friend</h4>
                                    </div>	
                                    <p>In need of funds for medical treatment or know someone who might be? Share the details and Akcel will get in touch with.</p>
                                    <Link to={"#"} className="btn btn-outline-primary"
                                        onClick={()=>setReferModal(true)}
                                    >Refer Now</Link>
                                </div> */}
                                <div className="clear" id="comment-list">
                                    <div className="comments-area" id="comments">
                                        <div className="widget-title style-1">
                                            <h4 className="title">Commentaires</h4>
                                        </div>
                                        <div className="clearfix">                                            
                                            <ol className="comment-list">
                                                {cardData?.comments?.map(comment =>  <li className="comment">
                                                    <CommentBlog title={comment.name} comment={comment.comment} image={avat3}/>
                                                </li>)}
                                                {(!cardData?.comments || cardData?.comments.length < 1) && 'Aucun commentaire pour l\'instant'}
                                                {/* <li className="comment">
                                                    <CommentBlog title="Celesto Anderson"  image={avat1}/> */}
                                                    {/* <ol className="children">
                                                        <li className="comment odd parent"></li>
                                                        <CommentBlog title="Jake Johnson" image={avat2}/>
                                                    </ol> */}
                                                {/* </li>
                                                <li className="comment">
                                                    <CommentBlog title="John Doe" image={avat3}/>
                                                </li>
                                                <li className="comment">
                                                    <CommentBlog title="Celesto Anderson" image={avat4}/>
                                                </li> */}

                                            </ol>
                                            <div className="comment-respond" id="respond">
                                                <div className="widget-title style-1">
                                                    <h4 className="title" id="reply-title">Laissez votre commentaire
                                                        <small><Link to={"#"} style={{display:"none"}} id="cancel-comment-reply-link" rel="nofollow">Cancel reply</Link></small>
                                                    </h4>
                                                </div>
                                                <form className="comment-form" id="commentform">
                                                    <p className="comment-form-author">
                                                        <label htmlFor="author">Nom <span className="required">*</span></label>
                                                        <input type="text" name="Author"  placeholder="Nom" id="author" />
                                                    </p>
                                                    <p className="comment-form-email">
                                                        <label htmlFor="email">Email <span className="required">*</span></label>
                                                        <input type="text" placeholder="Email" name="email" id="email" />
                                                    </p>
                                                    <p className="comment-form-comment">
                                                        <label htmlFor="comment">Commentaire</label>
                                                        <textarea rows="8" name="comment" placeholder="Commentaire" id="comment"></textarea>
                                                    </p>
                                                    <p className="form-submit">
                                                        <button type="submit" className="btn btn-primary" id="submit">Envoyer</button>
                                                    </p>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <aside className="side-bar sticky-top">
                                    <div className="widget style-1 widget_donate">
                                        <Link onClick={() => {modalRef.current.handleModalOpen(); }} className="btn btn-donate btn-primary w-100"  data-bs-toggle="modal" data-bs-target="#modalDonate"><i className="flaticon-like me-3"></i> Faire un don </Link>
                                        {/* <div className="tagcloud"> 
                                            <Link to={"#"}  data-bs-toggle="modal" data-bs-target="#modalDonate">Cards</Link>
                                            <Link to={"#"}  data-bs-toggle="modal" data-bs-target="#modalDonate">Net Banking</Link>
                                            <Link to={"#"}  data-bs-toggle="modal" data-bs-target="#modalDonate">UPI</Link>
                                        </div> */}
                                        {/* <a href="https://www.facebook.com/" target="_blank" rel="noreferrer" className="btn btn-primary facebook w-100 btn-bottom"><i className="fa-brands fa-facebook-square me-2"></i> Partager</a> */}
                                    </div>
                                    
                                    {/* <!--  Widget Fund --> */}
                                    <div className="widget style-1 widget_fund">
                                        <h3 className="title">8,500 €</h3>
                                        <p>atteint sur un objectif de <span>10,000 €</span></p>
                                        <div className="progress-bx style-1">
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-secondary progress-bar-striped progress-bar-animated" role="progressbar" style={{width:"90%"}}></div>
                                            </div> 
                                        </div>
                                        <ul className="detail">
                                            <li className="d-flex"><h5>120</h5><span className="ms-2">Donateurs</span></li>
                                            <li className="d-flex"><h5>52</h5><span className="ms-2">jours restant</span></li>
                                        </ul>
                                    </div>
                                    
                                    {/* <!-- Fundraiser Post --> */}
                                    {/* <div className="widget style-1 recent-posts-entry">
                                        <div className="widget-title">
                                            <h5 className="title">Fundraiser Post</h5>
                                        </div>	
                                        <div className="widget-post-bx">
                                            {postBlog.map((data, ind)=>(
                                                <div className="widget-post clearfix" key={ind}>
                                                    <div className="dz-media"> 
                                                        <img src={data.image} alt="" />
                                                    </div>
                                                    <div className="dz-info">
                                                        <h6 className="title"><Link to={"/blog-details"}>{data.title}</Link></h6>
                                                        <div className="dz-meta">
                                                            <ul>
                                                                <li className="post-date"><i className="flaticon-placeholder"></i> Coimbatore</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div> */}
                                    
                                    {/* <!-- Fundraising Team --> */}
                                    {/* <div className="widget style-1 widget_avatar">
                                        <div className="widget-title">
                                            <h5 className="title">Fundraising Team</h5>
                                        </div>
                                        <div className="avatar-wrapper">
                                            {teamBlog.map((data, ind)=>(
                                                <div className="avatar-item" key={ind}>
                                                    <div className="avatar-media"> 
                                                        <img src={data.image} alt="" />
                                                    </div>
                                                    <div className="avatar-info">
                                                        <h6 className="title"><Link to={"#"}>{data.title}</Link></h6>
                                                    </div>
                                                </div>
                                            ))}                                            
                                        </div>
                                    </div> */}
                                    
                                    {/* <!-- Top Donors --> */}
                                    <div className="widget style-1 widget_avatar">
                                        <div className="widget-title">
                                            <h5 className="title">Top Donateurs</h5>
                                        </div>
                                        <div className="avatar-wrapper">
                                            {donorsBlog.map((item, ind)=>(
                                                <div className="avatar-item" key={ind}>
                                                    <div className="avatar-media"> 
                                                        <img src={item.image} alt="" />
                                                    </div>
                                                    <div className="avatar-info">
                                                        <h6 className="title"><Link to={"#"}>{item.title}</Link></h6>
                                                        <span className="donors-item">{item.price}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </aside>
                            </div>            
                        </div>
                    </div>
                </section>
                <div className="call-action style-1 content-inner-1">
			        <div className="container">
                        <UpdateBlog />
                    </div>
                </div>
            </div>  
            {/* <Modal className="modal fade modal-wrapper" id="modalRefer" show={referModal} onHide={setReferModal} centered>               
                <div className="modal-header">
                    <h5 className="modal-title">Refer a Friend</h5>
                    <button type="button" className="btn-close" onClick={()=>setReferModal(false)}><i className="flaticon-close"></i></button>
                </div>
                <div className="modal-body">
                    <form className="dz-form contact-bx" method="POST">
                        <div className="form-group style-1 align-items-center">
                            <label className="form-label">Name</label>
                            <div className="input-group">
                                <input name="dzName" required="" type="text" className="form-control" placeholder="Jakob Bothman" />
                            </div>
                        </div>
                        <div className="form-group style-1 align-items-center">
                            <label className="form-label">Email address</label>
                            <div className="input-group">
                                <input name="dzEmail" required="" type="text" className="form-control" placeholder="marseloque@mail.com" />
                            </div>
                        </div>
                        <div className="form-group style-1 align-items-center">
                            <label className="form-label">Phone Number</label>
                            <div className="input-group">
                                <input name="dzPhoneNumber" type="number" className="form-control" placeholder="Phone Number" />
                            </div>
                        </div>
                        <div className="form-group mb-0 text-center">
                            <button name="submit" type="submit" value="Submit" className="btn btn-primary">Refer Now</button>
                        </div>
                    </form>
                </div>
            </Modal> */}
            <DonateModal 
                ref={modalRef}
            />
        </>
    )
}

export default FundraiserDetail;