import React from 'react';
import {Link} from 'react-router-dom';

import bg from '../assets/images/background/bg2.jpg';
import { useTranslation } from 'react-i18next';

const Error = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="error-page" style={{backgroundImage: "url("+ bg + ")"}}>
                <div className="error-inner text-center">
                    <div className="dz-error">404</div>
                    <h2 className="error-head">{t("NOT_FOUND")}</h2>
                    <Link to={"/"} className="btn btn-secondary">{t("BACK_HOME")}</Link>
                </div>
            </div>
        </>
    );
};


export default Error;