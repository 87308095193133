import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import PageBanner from '../layouts/PageBanner';
import ProjectMasonry from '../components/Project/ProjectMasonry';
import UpdateBlog from '../components/Home/UpdateBlog';

import bg from '../assets/images/banner/bnr6.jpg';

const Project = () => {
    const [dropbtn2,setDropbtn2] = useState('Toutes Categories');
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState("");
    return (
        <>
            <div className="page-content bg-white">
                <PageBanner maintitle="HOME" pagetitle="OUR_PROJECTS" background={bg}/>
                <div className="find-bx-wrapper">
                    <div className="container">
                        <div className="find-bx bg-white">
                            <form>
                                <div className="row align-items-center">
                                    <div className="col-lg-3 col-md-4">
                                        <div className="">
                                            <Dropdown className="select-drop-2">
                                                <Dropdown.Toggle as="div" className="i-false select-drop-btn-2">
                                                    <span>{dropbtn2}</span>
                                                    <i className="fa-regular fa-angle-down"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={()=>setDropbtn2('Toutes Categories')}>Toutes Categories</Dropdown.Item>
                                                    <Dropdown.Item onClick={()=>setDropbtn2('Newest')}>Newest</Dropdown.Item>
                                                    <Dropdown.Item onClick={()=>setDropbtn2('Oldest')}>Oldest</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-lg-9 col-md-8">
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder="Trouver un projet" />
                                            <div className="input-group-prepend">
                                                <span className="btn"><i className="las la-search"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="tag-list"> 
                            <span className="title text-black">Recherche populaire:</span>
                            <Link to={"#"}>{t("ZAKAT")},</Link>
                            <Link to={"#"}>{t("FOOD_AID")},</Link>
                            <Link to={"#"}>{t("WELLS")}</Link>
                        </div>
                    </div>
                </div>
                <section className="content-inner-2">
			        <div className="container">
                        <ProjectMasonry searchValue={searchValue ?? ""} />
                    </div>
                </section>
                <div className="call-action style-1 content-inner-1">
                    <div className="container">
                        <UpdateBlog />        
                    </div>
                </div>
            </div>
        </>
    );
};

export default Project;
