import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      HOME: "Home",
      email: "contact@kudshane.org",
      CORPORATE: "Corporate",
      OUR_PROJECTS: "Our Projects",
      PROJECT_DETAIL: "Project details",
      BE_VOLUNTER: "Be Volunter",
      CONTACT: "Contact Us",
      ABOUT_US: "About Us",
      LEGAL_NOTICES: "Legal notices",
      BANK_ACCOUNT_NUMBER: "Bank Account Numbers",
      ZAKAT: "Zakat",
      NAZR_AQIA_CHUKR: "Nazr, Aqia, Chukr",
      ORPHANS: "Orphans",
      FOOD_AID: "Food Aid",
      SHARE_THE_MEAL: "Share The Meal",
      WELLS: "Wells",
      WHO_WE_ARE: "Who We Are",
      ORG_KUDSHANE_CREATED: "Association Kudshane a été créée en 2023",
      KUDS: "Kuds",
      KUDS_EXPLAIN: "Le mot « kuds » qui signifie en arabe sacré.",
      HANE: "Hane",
      HANE_EXPLAIN: "Le mot « hane » signifie en ottoman le foyer.",
      KUDSHANE_EXPLAIN: "L’association kudshane a été créée en 2023 par Eda Çiçekçi et ses amis bénévoles. Kudshane est la signification pure du « foyer sacré » qui a été créé dans le but de donner un foyer à ceux qui recherchent à la fois la coopération qui est un fait sacré mais l'amour et la chaleur à travers l’humanitaire.",
      NOT_FOUND: "We are sorry. But the page you are looking for cannot be found.",
      BACK_HOME: "BACK TO HOMEPAGE"

    }
  },
  fr: {
    translation: {
      HOME: "Accueil",
      email: "contact@kudshane.org",
      CORPORATE: "Organisation",
      OUR_PROJECTS: "Nos Projets",
      PROJECT_DETAIL: "Détail du projet",
      BE_VOLUNTER: "Nous Rejoindre",
      CONTACT: "Nous Contacter",
      ABOUT_US: "À propos de nous",
      LEGAL_NOTICES: "Mentions Légales",
      BANK_ACCOUNT_NUMBER: "Numéro de banque",
      ZAKAT: "Zakat",
      NAZR_AQIA_CHUKR: "Nazr, Aqia, Chukr",
      ORPHANS: "Orphelins",
      FOOD_AID: "Aide alimentaire",
      SHARE_THE_MEAL: "Repas offert",
      WELLS: "Puits",
      WHO_WE_ARE: "Qui Sommes Nous",
      ORG_KUDSHANE_CREATED: "Association Kudshane a été créée en 2023",
      KUDS: "Kuds",
      KUDS_EXPLAIN: "Le mot « kuds » qui signifie en arabe sacré.",
      HANE: "Hane",
      HANE_EXPLAIN: "Le mot « hane » signifie en ottoman le foyer.",
      KUDSHANE_EXPLAIN: "L’association kudshane a été créée en 2023 par Eda Çiçekçi et ses amis bénévoles. Kudshane est la signification pure du « foyer sacré » qui a été créé dans le but de donner un foyer à ceux qui recherchent à la fois la coopération qui est un fait sacré mais l'amour et la chaleur à travers l’humanitaire.",
      NOT_FOUND: "Nous sommes désolés. Mais la page que vous recherchez est introuvable.",
      BACK_HOME: "RETOUR À LA PAGE D'ACCUEIL"
    }
  },
  tr: {
    translation: {
        HOME: "Anasayfa",
        email: "contact@kudshane.org",
        CORPORATE: "Kurumsal",
        OUR_PROJECTS: "Projelerimiz",
        BE_VOLUNTER: "Gönüllü Ol",
        CONTACT: "İletişim",
        ABOUT_US: "Hakkımızda",
        LEGAL_NOTICES: "Yasal Uyarı",
        BANK_ACCOUNT_NUMBER: "Hesap Numerası",
        ZAKAT: "Zekat - Fitre",
        NAZR_AQIA_CHUKR: "Adak Akika Kurbanı",
        ORPHANS: "Yetim",
        FOOD_AID: "Kumanya",
        SHARE_THE_MEAL: "İftar sofrası",
        WELLS: "Su kuyuları",
        WHO_WE_ARE: "Biz Kimiz",
        ORG_KUDSHANE_CREATED: "Association kudshane a été créée en 2023",
        KUDS: "Kuds",
        KUDS_EXPLAIN: "Le mot « kuds » qui signifie en arabe sacré.",
        HANE_EXPLAIN: "Le mot « hane » signifie en ottoman le foyer.",
        KUDSHANE_EXPLAIN: "L’association kudshane a été créée en 2023 par Eda Çiçekçi et ses amis bénévoles. Kudshane est la signification pure du « foyer sacré » qui a été créé dans le but de donner un foyer à ceux qui recherchent à la fois la coopération qui est un fait sacré mais l'amour et la chaleur à travers l’humanitaire.",
        NOT_FOUND: "Nous sommes désolés. Mais la page que vous recherchez est introuvable.",
        BACK_HOME: "RETOUR À LA PAGE D'ACCUEIL"
      }
  }
};

i18n
.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;