/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
import React,{useState, useEffect, useReducer, useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import {MenuListArray2} from './Menu';
import { useTranslation } from 'react-i18next';
import englishIcon from "./../assets/images/englishIcon.png"
import { useUser } from "../react-query/users/useUser";
import { useInstaPictures } from "../react-query/instagram/useInstagram";
import franceIcon from "./../assets/images/franceIcon.png"
import turkeyIcon from "./../assets/images/turkeyIcon.png"
import UserContext from "../configuration/userContext";
import { signInWithGoogle, logInWithEmailAndPassword, registerWithEmailAndPassword, logout } from "../configuration/firebase";
import {Dropdown} from 'react-bootstrap';
import 'dayjs/locale/en'
import 'dayjs/locale/tr'
import 'dayjs/locale/fr'

import logo from './../assets/images/logo.png';

const Header = () => {

    //form submit
    const nav = useNavigate();
    const formSubmit = (e) => {
        e.prevantDefault();
        nav("/");
    }

    /* for sticky header */
	const [headerFix, setheaderFix] = React.useState(false);
	useEffect(() => {
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});
	}, []); 

    //Modal
    const [loginModal, setloginModal] = useState(false);
    const [resetModal, setResetModal] = useState(false);
    const [signupModal, setSignupModal] = useState(false);
    //Modals end

    const [sidebarOpen, setSidebarOpen] = useState(false);	
    
    useEffect(() => {
		var mainMenu = document.getElementById('OpenMenu'); 
		if(mainMenu){
			if(sidebarOpen){
				mainMenu.classList.add('show');
			}else{
				mainMenu.classList.remove('show');
			}
		}	
	});
	
	// Menu dropdown list 
    const reducer = (previousState, updatedState) => ({
        ...previousState,
        ...updatedState,
    });
    const initialState = {
        active : "",
        activeSubmenu : "",
    }
    const [state, setState] = useReducer(reducer, initialState);
	const handleMenuActive = status => {		
        setState({active : status});		
            if(state.active === status){			
          setState({active : ""});
        }
    }
    const handleSubmenuActive = (status) => {		
        setState({activeSubmenu : status})
        if(state.activeSubmenu === status){
            setState({activeSubmenu : ""})                
        }    
    }
    const { t, i18n} = useTranslation();
    const user = useContext(UserContext)?.user;
    

    const {data: userData} = useUser(user?.uid ?? "");
    useInstaPictures();

    const standardLogin = async(e) => {
        e.preventDefault();

        const { elements } = e.target;

        const email = elements.email.value;
        const password = elements.password.value;
        await logInWithEmailAndPassword(email, password)
        setloginModal(false)
    }

    const standardSignup = async(e) => {
        e.preventDefault();
        const { elements } = e.target;

        const name = elements.name.value;
        const email = elements.email.value;
        const password = elements.password.value;
        await registerWithEmailAndPassword(name, email, password);
        setSignupModal(false)
    }


    //let path = window.location.pathname;
	// Menu dropdown list End
    const changeLanguage = (l) => {
        i18n.changeLanguage(l)
    }
    return (
        <>
            <header className="site-header mo-left header style-1">
                <div className="top-bar">
                    <div className="container">
                        <div className="dz-topbar-inner d-flex justify-content-between align-items-center">
                            <div className="dz-topbar-left">
                                <ul>
                                    <li>Welcome to Kudshane, Charity Agency</li>
                                    {!user?.email && <li>
                                        <Link to={"#"} className="badge badge-primary btn-login" data-bs-toggle="modal" data-bs-target="#modalLogin" 
                                            onClick={()=>setloginModal(true)}
                                        >
                                            <i className="flaticon-logout me-2" ></i>Login / Sign Up
                                        </Link>
                                    </li>}
                                </ul>
                            </div>
                            <div className="dz-topbar-right">
                                <ul style={{display: "flex", alignItems: "center"}}>
                                {userData?.isAdmin && <li><i className="fa-solid fa-gears"></i>Admin</li>}
                                    <li class="sub-menu-down">
                        <Dropdown className="select-drop">
                            <Dropdown.Toggle as="div" style={{height: "40px", gap: "0.5rem", padding: "12px 12px"}} className="i-false select-drop-btn">
                                <span style={{display: "flex", gap:"0.5rem", alignItems: "center"}}>
                                    {i18n.language === "en" && <img style={{width: "20px"}} class="flag-united-kingdom flag m-0" src={englishIcon} />}
                                    {i18n.language === "fr" && <img style={{width: "20px"}} class="flag-united-kingdom flag m-0" src={franceIcon} />}
                                    {i18n.language === "tr" && <img style={{width: "20px"}} class="flag-united-kingdom flag m-0" src={turkeyIcon} />}
                                    {i18n.language === "en" ? "English" : i18n.language === "fr" ? "Français" : "Turkçe"}</span>
                                <i className="fa-regular fa-angle-down"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item style={{display: "flex", gap:"0.5rem", alignItems: "center"}} onClick={()=>{changeLanguage('tr')}}><img style={{width: "20px"}} class="flag-united-kingdom flag m-0" src={turkeyIcon} />Turkçe</Dropdown.Item>
                                <Dropdown.Item style={{display: "flex", gap:"0.5rem", alignItems: "center"}} onClick={()=>{changeLanguage('fr')}}><img style={{width: "20px"}} class="flag-united-kingdom flag m-0" src={franceIcon} />Français</Dropdown.Item>
                                <Dropdown.Item style={{display: "flex", gap:"0.5rem", alignItems: "center"}} onClick={()=>{changeLanguage('en')}}><img style={{width: "20px"}} class="flag-united-kingdom flag m-0" src={englishIcon} />English</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                                    
                                    </li>

                                    <li><i className="fas fa-map-marker-alt"></i> 394-091-3312</li>
                                    <li><i className="fas fa-envelope"></i>{t("email")} </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`sticky-header main-bar-wraper navbar-expand-lg ${headerFix ? "is-fixed" : ""}`}>
                    <div className="main-bar clearfix ">
                        <div className="container clearfix">
                            <div className="logo-header mostion logo-dark">
                                <Link to={"/"}><img src={logo} alt="" /></Link>
                            </div>                        
                            <button                                 
                                className={`navbar-toggler navicon justify-content-end ${sidebarOpen ? 'open' : 'collapsed' }`}
                                type="button"
                                onClick={()=>setSidebarOpen(!sidebarOpen)}
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                            <div className="extra-nav">
                                {user?.email && (<><div className="extra-cell">
                                    <Link className="btn btn-outline-secondary btnhover1">
                                        { user?.photoURL ? <img width={22} height={22} src={user?.photoURL} /> : <i className="fa-solid fa-user"></i>}
                                        <span className="m-l10">{user?.displayName || user?.email}</span>
                                    </Link>
                                </div>
                                
                                <div className="extra-cell">
                                    <button onClick={logout} className="btn btn-secondary">
                                        <i className="fa-solid fa-arrow-right-from-bracket"></i>
                                        <span className="m-l10">logout</span>
                                    </button>
                                </div></>)}
                            </div>
                            <div
                                className={`header-nav navbar-collapse collapse justify-content-end ${sidebarOpen ? "show" : ""}`}
                                id="navbarNavDropdown"
                            >
                                <div className="logo-header logo-dark">
                                    <Link to={"/"}><img src={logo} alt="" /></Link>
                                </div>
                                {userData?.isAdmin && <div className="admin"><i className="fa-solid fa-gears"></i>Admin</div>}
                                <Dropdown style={{padding: "1rem"}} className={`select-drop translation ${sidebarOpen ? "show" : ""}`}>
                            <Dropdown.Toggle as="div" style={{height: "40px", gap: "0.5rem", padding: "12px 12px"}} className="i-false select-drop-btn">
                                <span style={{display: "flex", gap:"0.5rem", alignItems: "center"}}>
                                    {i18n.language === "en" && <img style={{width: "20px"}} class="flag-united-kingdom flag m-0" src={englishIcon} />}
                                    {i18n.language === "fr" && <img style={{width: "20px"}} class="flag-united-kingdom flag m-0" src={franceIcon} />}
                                    {i18n.language === "tr" && <img style={{width: "20px"}} class="flag-united-kingdom flag m-0" src={turkeyIcon} />}
                                    {i18n.language === "en" ? <span>English</span> : i18n.language === "fr" ? <span>Français</span> : <span>Turkçe</span>}</span>
                                <i className="fa-regular fa-angle-down"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                            <Dropdown.Item style={{display: "flex", gap:"0.5rem", alignItems: "center"}} onClick={()=>i18n.changeLanguage('tr')}><img style={{width: "20px"}} class="flag-united-kingdom flag m-0" src={turkeyIcon} />Turkçe</Dropdown.Item>
                                <Dropdown.Item style={{display: "flex", gap:"0.5rem", alignItems: "center"}} onClick={()=>i18n.changeLanguage('fr')}><img style={{width: "20px"}} class="flag-united-kingdom flag m-0" src={franceIcon} />Français</Dropdown.Item>
                                <Dropdown.Item style={{display: "flex", gap:"0.5rem", alignItems: "center"}} onClick={()=>i18n.changeLanguage('en')}><img style={{width: "20px"}} class="flag-united-kingdom flag m-0" src={englishIcon} />English</Dropdown.Item>
                                </Dropdown.Menu>
                        </Dropdown>    
                                
                                <ul className="nav navbar-nav navbar navbar-left">
              
                                    {MenuListArray2.map((data, index)=>{
                                        let menuClass = data.classChange;
                                        if(menuClass !== "sub-menu-down"){
                                            return(
                                                <li className={menuClass}  key={index} ><Link to={data.to}>{t(data.title)}</Link></li>
                                            )
                                        }else{
                                            return(				
                                                <li className={`${menuClass} ${ state.active === data.title ? 'open' : ''}`}
                                                    key={index} 
                                                >
                                                
                                                    {data.content && data.content.length > 0 ?
                                                        <Link to={"#"}                                                       
                                                            onClick={() => {handleMenuActive(data.title)}}
                                                        >				
                                                            {t(data.title)}
                                                        </Link>
                                                    :
                                                        <Link  to={data.to} > 
                                                            {t(data.title)}                                                        
                                                        </Link>
                                                    }
                                                    <Collapse in={state.active === data.title ? true :false}>
                                                        <ul className={`sub-menu ${menuClass === "mm-collapse" ? "open" : ""}`}>
                                                            {data.content && data.content.map((data,index) => {									
                                                                return(	
                                                                    <li key={index}
                                                                        className={`${ state.activeSubmenu === data.title ? "open" : ""}`}  
                                                                    >
                                                                    {data.content && data.content.length > 0 ?
                                                                        <>
                                                                            <Link to={data.to} 
                                                                                onClick={() => { handleSubmenuActive(data.title)}}
                                                                            >
                                                                                {t(data.title)}
                                                                                <i className="fa fa-angle-right" />
                                                                            </Link>
                                                                            <Collapse in={state.activeSubmenu === data.title ? true :false}>
                                                                                <ul className={`sub-menu ${menuClass === "mm-collapse" ? "open" : ""}`}>
                                                                                    {data.content && data.content.map((data,index) => {
                                                                                        return(	
                                                                                        <>
                                                                                            <li key={index}>
                                                                                                <Link  to={data.to}>{t(data.title)}</Link>
                                                                                            </li>
                                                                                        </>
                                                                                        )
                                                                                    })}
                                                                                </ul>
                                                                            </Collapse>
                                                                        </>
                                                                        :
                                                                        <Link to={data.to}>
                                                                            {t(data.title)}
                                                                        </Link>
                                                                    }
                                                                    
                                                                    </li>
                                                                
                                                                )
                                                            })}
                                                        </ul>
                                                    </Collapse>
                                                </li>	
                                            )
                                        }
                                    })}     
                                </ul>
                                <div className="header-bottom">
                                    <div style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
                                    {!user?.email && <Link to={"#"} className="btn btn-light btn-login btn-sm"  data-bs-target="#modalLogin"
                                        onClick={()=>setloginModal(true)}
                                    >
                                        <i className="flaticon-logout me-3"></i>Login / Sign Up
                                    </Link>}
                               
                                {user?.email && (<><div className="extra-cell">
                                    <Link className="btn btn-light btn-login btn-sm">
                                        { user?.photoURL ? <img width={22} height={22} src={user?.photoURL} /> : <i className="fa-solid fa-user"></i>}
                                        <span className="m-l10">{user?.displayName || user?.email}</span>
                                    </Link>
                                </div>
                                
                                <div className="extra-cell">
                                    <button style={{width: "100%"}} onClick={logout} className="btn btn-secondary btn-login btn-sm">
                                        <i className="fa-solid fa-arrow-right-from-bracket"></i>
                                        <span className="m-l10">logout</span>
                                    </button>
                                </div></>)}
                                    </div>
  
                                    <div className="dz-social-icon">
                                        <ul>
                                            <li><a className="fab fa-facebook-f" href="https://www.facebook.com/"  target="_blank" rel="noreferrer"></a></li>
                                            {" "}<li><a className="fab fa-twitter" href="https://twitter.com/" target="_blank" rel="noreferrer"></a></li>
                                            {" "}<li><a className="fab fa-linkedin-in" href="https://www.linkedin.com/" target="_blank" rel="noreferrer"></a></li>
                                            {" "}<li><a className="fab fa-instagram" href="https://www.instagram.com/" target="_blank" rel="noreferrer"></a></li>
                                        </ul>
                                    </div>	
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </header> 
            <Modal className="fade modal-wrapper auth-modal" id="modalLogin" show={loginModal} onHide={setloginModal} centered>               
                <h2 className="title">Login Your Account</h2>
                <form onSubmit={(e)=>standardLogin(e)}>
                    <div className="form-group">
                        <input type="email" id="email" className="form-control" placeholder="Email Address" />
                    </div>
                    <div className="form-group">
                        <input type="password" id="password" className="form-control" placeholder="Password" />
                        <div className="reset-password">
                            <Link to={"#"} className="btn-link collapsed"                                  
                                onClick={()=>(setResetModal(true),setloginModal(false))}
                            >Reset password?</Link>
                        </div>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-outline-primary btn-block">Login</button>
                    </div>
                    {/* <div className="form-group">
                        <div onClick={() => loginFacebook()} className="btn facebook btn-block"><i className="fa-brands fa-facebook-f m-r10"></i>Log in with Facebook</div>
                    </div> */}
                    <div className="form-group">
                        <div onClick={() => {
                            signInWithGoogle()
                            setloginModal(false)}} className="btn google-plus btn-block"><i className="fa-brands fa-google m-r10"></i>Log in with Google</div>
                    </div>
                    <div className="sign-text">
                        <span>Don't have a Crowdfunding account? 
                            <Link to={"#"} className="btn-link collapsed" 
                                onClick={()=>(setSignupModal(true), setloginModal(false))}
                            > Sign up</Link>
                        </span>
                    </div>
                </form>                
            </Modal>
            <Modal className="modal fade modal-wrapper auth-modal"  show={resetModal} onHide={setResetModal} centered>
                <div  className="reset-password" id="reset-password">
                    <h2 className="title">Reset password?</h2>
                    <form onSubmit={(e)=>formSubmit(e)}>
                        <div className="form-group password-icon-bx">
                            <i className="fa fa-lock"></i>
                            <p>Enter your email address associated with your account, and we'll email you a link to reset your password...</p>
                        </div>
                        <div className="form-group">
                            <input type="email" className="form-control" placeholder="Enter email address" />
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-outline-primary btn-block">Send reset link</button>
                        </div>
                        <Link to={"#"} className="sign-text d-block" data-bs-toggle="collapse" 
                            onClick={()=>(setResetModal(false),setloginModal(true))}
                            >Back
                        </Link>
                    </form>
                </div>  
            </Modal>
            <Modal className="modal fade modal-wrapper auth-modal"  show={signupModal} onHide={setSignupModal} centered>               
                <h2 className="title">Sign Up Your Account</h2>
                <form onSubmit={(e)=>standardSignup(e)}>
                <div className="form-group">
                        <input type="text" id="name" className="form-control" placeholder="Name" />
                    </div>
                    <div className="form-group">
                        <input type="email" id="email" className="form-control" placeholder="Email Address" />
                    </div>
                    <div className="form-group">
                        <input type="password" id="password" className="form-control" placeholder="Password" />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-outline-primary btn-block">Sign Up</button>
                    </div>
                    {/* <div className="form-group">
                        <Link to={"#"} className="btn facebook btn-block"><i className="fa-brands fa-facebook-f m-r10"></i>Log in with Facebook</Link>
                    </div> */}
                    <div className="form-group">
                        <Link to={"#"} className="btn google-plus btn-block"><i className="fa-brands fa-google m-r10"></i>Log in with Google</Link>
                    </div>
                    <div className="sign-text">
                        <span>Don't have a Crowdfunding account? <Link to={"#"} className="btn-link collapsed" 
                            onClick={()=>(setSignupModal(false),setloginModal(true))}
                        >Login</Link></span>
                    </div>
                </form>                
            </Modal>
        </>
    );
};


export default Header;